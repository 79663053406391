import _ from 'lodash';
import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { colors } from '@commonsku/styles';
import { Chart } from '../../helpers';
import { PROJECT_STAGES } from '../utils';
import { createChartColors, OrderTypesColor } from '../utils/chart_utils';

const StuckInStageData = React.memo((props) => {
  const {
    data,
    onClickColumn,
  } = props;

  const stuckInStageData = React.useMemo(() => {
    return Object.keys(PROJECT_STAGES).map(v => {
      const count = _.get(data, v, 0);
      return {
        ...PROJECT_STAGES[v],
        order_type: v,
        count: parseInt(count),
      };
    });
  }, [data]);

  return (
    <Chart
      type="bar"
      width="100%"
      data={stuckInStageData}
      onChartReady={stuckInStageChart({ onClickColumn })}
      height="350px"
    />
  );
});

export function stuckInStageChart({ onClickColumn = null }) {
  return function (chart, data) {
    chart.colors.list = createChartColors([
      OrderTypesColor['OPPORTUNITY'].main,
      OrderTypesColor['PRESENTATION'].main,
      OrderTypesColor['ESTIMATE'].main,
      OrderTypesColor['SALES ORDER'].main,
      OrderTypesColor['INVOICE'].main,
    ]);

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "title";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.disabled = true;
    const label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 200;
    label.tooltipText = "{category}";

    categoryAxis.events.on("sizechanged", function (ev) {
      let axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < 150) {
        axis.renderer.labels.template.fontSize = 13;
      } else {
        axis.renderer.labels.template.fontSize = 15;
      }
    });

    categoryAxis.renderer.labels.template.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.maxPrecision = 0;
    valueAxis.renderer.minGridDistance = 100;

    categoryAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;

    categoryAxis.cursorTooltipEnabled = false;
    valueAxis.cursorTooltipEnabled = false;

    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = "Stage";
    series.dataFields.valueY = "count";
    series.dataFields.categoryX = "title";
    series.columns.template.tooltipText = "[bold]{valueY}[/]";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusTopRight = 2;
    series.columns.template.column.cornerRadiusTopLeft = 2;

    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    series.columns.template.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

    series.tooltip.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    series.tooltip.keepTargetHover = true;
    series.tooltip.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

    const cursor = new am4charts.XYCursor();
    chart.cursor = cursor;
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = "none";

    valueAxis.renderer.labels.template.fill = colors.neutrals['100'];
    categoryAxis.renderer.labels.template.fill = colors.neutrals['100'];

    chart.numberFormatter.numberFormat = "#a";
  };
}

export default StuckInStageData;
